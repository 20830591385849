/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 1, 2017 */

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('proxima_nova_alt_bold-webfont.woff2') format('woff2'),
    url('proxima_nova_alt_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('proxima_nova_alt_reg-webfont.woff2') format('woff2'),
    url('proxima_nova_alt_reg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Alt Semibold';
  src: url('proxima_nova_alt_sbold-webfont.woff2') format('woff2'),
    url('proxima_nova_alt_sbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Alt Thin';
  src: url('proxima_nova_alt_thin-webfont.woff2') format('woff2'),
    url('proxima_nova_alt_thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Alt Extrabold';
  src: url('proxima_nova_alt_xbold-webfont.woff2') format('woff2'),
    url('proxima_nova_alt_xbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
